import {ICollapsibleContent, INavigationMenu} from './main-navigation.types';
import {EFunctionalConstantEnums} from '@shared/constants/e-functional-constant.enums';


const monitoringCollapse: ICollapsibleContent[] = [
	{
		text: 'Карта оборудования',
		isActive: false,
		route: '/monitoring/map',
		path: 'map',
	},
	{
		text: 'Список оборудования',
		isActive: false,
		route: '/monitoring/devices-info',
		path: 'devices-info',
	}
];

const documentationCollapse: ICollapsibleContent[] = [
	{
		text: 'По расположению',
		isActive: false,
		route: '/docs',
		path: '',
	},
	{
		text: 'По каталогу',
		isActive: false,
		route: '/docs/ftp?path=%2FDOCUMENTATION',
		path: 'ftp',
	}
];

const statisticCollapse: ICollapsibleContent[] = [
	{
		text: 'Диаграмма',
		isActive: false,
		route: '/stat?type=diagrams',
		path: '',
	},
	{
		text: 'Динамическая загрузка',
		isActive: false,
		route: '/stat?type=dyn_trends',
		path: '',
	},
	{
		text: 'Статическая загрузка',
		isActive: false,
		route: '/stat?type=static_trends',
		path: '',
	},
	{
		text: 'Простои',
		isActive: false,
		route: '/stat?type=statistic_idles',
		path: '',
	},
	{
		text: 'Техпроцессы',
		isActive: false,
		route: '/stat?type=statistic_techproceses',
		path: '',
	},
	{
		text: 'Коэффициенты',
		isActive: false,
		route: '/stat?type=coefficients',
		path: '',
	},
	{
		text: 'Риски ремонта',
		isActive: false,
		route: '/stat?type=risks',
		path: '',
	},
	{
		text: 'Отчеты',
		isActive: false,
		route: '/stat?type=report',
		path: '',
	}
];

const repairCollapse: ICollapsibleContent[] = [
	{
		text: 'Заявки',
		isActive: false,
		route: '/rep?type=List',
		path: '',
	},
	{
		text: 'Переходящие простои',
		isActive: false,
		route: '/rep?type=RollingIssues',
		path: '',
	}
];

const idlesCollapse: ICollapsibleContent[] = [
	{
		text: 'Простои оборудования',
		isActive: false,
		route: '/idle?type=List',
		path: '',
	},
	{
		text: 'Диаграммы',
		isActive: false,
		route: '/idle?type=Diagrams',
		path: '',
	}
];

const mesCollapse: ICollapsibleContent[] = [
	{
		text: 'Мои задачи MES',
		isActive: false,
		route: '/mes?type=OperatorMesTasks',
		path: '',
	},
	{
		text: 'Управление задачами MES',
		isActive: false,
		route: '/mes?type=MesTasks',
		path: '',
	},
	{
		text: 'Мои ремонты',
		isActive: false,
		route: '/mes?type=RepairTasks',
		path: '',
	},
	{
		text: 'Ремонты',
		isActive: false,
		route: '/mes?type=RepairTasksCorrect',
		path: '',
	},
	{
		text: 'Выбор станка по локации',
		isActive: false,
		route: '/mes?type=UnitByLocation',
		path: '',
	},

	{
		text: 'План',
		isActive: false,
		route: '/mes?type=Plan',
		path: '',
	},
];

const planningCollapse: ICollapsibleContent[] = [
	{
		text: 'Параметры',
		isActive: false,
		route: '/planning/parameter',
		path: 'parameter',
	},
	{
		text: 'Номенклатура',
		isActive: false,
		route: '/planning/nomenclature',
		path: 'nomenclature',
	},
	{
		text: 'Единицы отслеживания',
		isActive: false,
		route: '/planning/item',
		path: 'item',
	},
	{
		text: 'План',
		isActive: false,
		route: '/planning/plan',
		path: 'plan',
	},
];

const energyCollapse: ICollapsibleContent[] = [
	// {
	// 	text: 'Карта',
	// 	isActive: false,
	// 	route: '/resources/map',
	// 	path: 'map',
	// },
	{
		text: 'Диаграммы',
		isActive: false,
		route: '/resources/chart',
		path: 'chart',
	},
	{
		text: 'Список',
		isActive: false,
		route: '/resources/list',
		path: 'list',
	}
];

const nomenclatureCollapse: ICollapsibleContent[] = [
	{
		text: 'Каталог',
		isActive: false,
		route: '/nomenclature-warehouse/catalog',
		path: 'catalog',
	},
	{
		text: 'Движение материала',
		isActive: false,
		route: '/nomenclature-warehouse/movement',
		path: 'movement',
	}
];

const sdCollapse: ICollapsibleContent[] = [
	{
		text: 'Сервисы',
		isActive: false,
		route: '/service-desk/service',
		path: 'service',
	},
	{
		text: 'Задачи',
		isActive: false,
		route: '/service-desk/task-list',
		path: 'task-list',
	},
	{
		text: 'Расписание',
		isActive: false,
		route: '/service-desk/schedule',
		path: 'schedule',
	}
];

export const NAVIGATION_CONFIG: INavigationMenu[] = [
	{
		accordionState: 'initial',
		title: 'Выбрать площадку',
		icon: 'business',
		route: '/enterprise',
		description: 'Переход между площадками предприятия',
		rights: [EFunctionalConstantEnums.show_monitoring],
		isCollapsed: false,
		path: 'enterprise',
	},
	{
		accordionState: 'initial',
		title: 'Мониторинг',
		icon: 'desktop-outline',
		description: 'Модуль мониторинга оборудования в реальном времени',
		rights: [EFunctionalConstantEnums.show_monitoring],
		children: monitoringCollapse,
		isCollapsed: false,
		iconState: 'default',
		path: 'monitoring',
	},
	{
		accordionState: 'initial',
		title: 'Документация',
		icon: 'document-outline',
		description: 'Модуль документации к оборудованию',
		rights: [EFunctionalConstantEnums.ftp],
		mustEnvironments: ['rootLocationId', 'ftpServer'],
		children: documentationCollapse,
		isCollapsed: false,
		iconState: 'default',
		path: 'docs',
	},
	{
		accordionState: 'initial',
		title: 'Статистика',
		icon: 'stats-chart',
		description: 'Модуль отчетов и расчета OEE',
		rights: [EFunctionalConstantEnums.report],
		mustEnvironments: ['rootLocationId'],
		children: statisticCollapse,
		isCollapsed: false,
		iconState: 'default',
		path: 'stat',
	},
	/*{
		accordionState: 'initial',
		title: 'Ремонтные заявки',
		icon: 'construct',
		description: 'Модуль для ремонтного подразделения',
		disabled: noRootLocation || !report,
		children: this.repairCollapse,
		isCollapsed: false,
		iconState: 'default'
	},*/
	{
		accordionState: 'initial',
		title: 'Простои',
		icon: 'time',
		description: 'Простои оборудования',
		rights: [EFunctionalConstantEnums.idles],
		children: idlesCollapse,
		isCollapsed: false,
		iconState: 'default',
		path: 'idle',
	},
	{
		accordionState: 'initial',
		title: 'Энергоресурсы',
		icon: 'flash',
		description: 'Модуль мониторинга энергоресурсов',
		rights: [EFunctionalConstantEnums.askue],
		mustEnvironments: ['ascueRootLocationId'],
		children: energyCollapse,
		isCollapsed: false,
		iconState: 'default',
		path: 'resources',
	},
	// {
	// 	accordionState: 'initial',
	// 	title: 'MES',
	// 	icon: 'share-social',
	// 	description: 'Система управления производственными процессами',
	// 	disabled: false,
	// 	children: this.mesCollapse,
	// 	isCollapsed: false,
	// 	iconState: 'default'
	// },
	{
		accordionState: 'initial',
		title: 'SD',
		icon: 'bi-pencil',
		description: 'Service Desk (диспетчерская служба)',
		rights: [EFunctionalConstantEnums.sdweb],
		children: sdCollapse,
		isCollapsed: false,
		iconState: 'default',
		path: 'service-desk',
	},
	{
		accordionState: 'initial',
		title: 'Номенклатура',
		icon: 'nomenclature',
		description: 'Классификация и номенклатура продукции, ЗИП',
		rights: [EFunctionalConstantEnums.nomenclature],
		children: nomenclatureCollapse,
		isCollapsed: false,
		iconState: 'default',
		path: 'nomenclature-warehouse',
	},
	{
		accordionState: 'initial',
		title: 'Планирование',
		icon: 'planning',
		description: 'Система планирования производственных процессов',
		rights: [EFunctionalConstantEnums.plan],
		children: planningCollapse,
		isCollapsed: false,
		iconState: 'default',
		path: 'planning',
	},
	{
		accordionState: 'initial',
		title: 'О системе',
		icon: 'information-circle',
		route: '/about',
		description: 'Инструкция по эксплуатации',
		isCollapsed: false,
		path: 'about',
	},
];
