import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {MainNavigationService} from '../../layout/user-menu/services/main-navigation.service';
import {NotifierService} from 'angular-notifier';
import {INavigationMenu} from '../../layout/user-menu/models/main-navigation.types';

@Injectable({
	providedIn: 'root',
})
export class NavigationGuard implements CanActivate {
	private _url: string;
	private _menu: INavigationMenu[];

	constructor(
		private _navigationService: MainNavigationService,
		private _notifier: NotifierService,
		private _router: Router,
	) {
	}

	public async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		this._url = state.url;
		this._menu = await this._navigationService.getNav();

		const rootPath = this._url.split('?')[0]
			.split('/')
			.filter(i => i !== '')[0];

		const isHavePermissions = this.checkPathPermissions(rootPath);

		if (!isHavePermissions) {
			localStorage.removeItem('storedUrl');

			this._notifier.notify('error', 'Нет прав доступа!');
			this._router.navigate(['/login']);
		}

		return true;
	}

	private checkPathPermissions(rootPath: string): boolean {
		const menu = this._menu.find(
			i => i.path.toUpperCase() === rootPath.toUpperCase()
		);

		if (!menu || typeof menu.disabled !== 'boolean') {
			return true;
		}

		return !menu.disabled;
	}
}
