import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ConfigService } from '@shared/services/config.service';
import { first } from 'rxjs/operators';
import { INavigationMenu } from '../models/main-navigation.types';
import { NAVIGATION_CONFIG } from '../models/main-navigation.config';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { AccessSelector } from '@store/accesses-store/access.selector';


@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class MainNavigationService {
	private _nav: BehaviorSubject<INavigationMenu[]>
		= new BehaviorSubject<INavigationMenu[]>(null);

	constructor(
		private _config: ConfigService,
		private _accessSelector: AccessSelector,
	) {
		this.init().then();
	}

	private async init(): Promise<void> {
		await new Promise((resolve) => {
			this._accessSelector.getFilledPermissions$()
				.pipe(first())
				.subscribe(resolve);
		});

		this._nav.next(this.getMenuWithPermissions());
	}

	private getMenuWithPermissions(): INavigationMenu[] {
		const menu = _.cloneDeep(NAVIGATION_CONFIG);

		this.checkDisabledMenu(menu);

		return menu;
	}

	private checkDisabledMenu(menu: INavigationMenu[]): void {
		menu.filter(i => !!i.mustEnvironments?.length).forEach(i => {
			const config = this._config.getConfig(i.mustEnvironments);
			const isHaveAllEnvironments = i.mustEnvironments.every(
				k => Boolean(config[k])
			);

			i.disabled = !(isHaveAllEnvironments
				? i.disabled !== true
				: false);
		});

		menu.filter(i => !!i.rights?.length).forEach(i => {
			i.disabled = !(this._accessSelector.isAnyFunctionalAvailable(i.rights)
				? i.disabled !== true
				: false);
		});
	}

	public getNav(): Promise<INavigationMenu[]> {
		return new Promise((resolve) => this._nav.subscribe(i => i && resolve(i)));
	}
}
