import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable()
export class UserGuard implements CanActivate {
	constructor(private _router: Router) {
	}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | boolean {
		const authorized = !!document.cookie.match(new RegExp('(^| )accessToken=([^;]+)'));

		if (!authorized) {
			this._router.navigateByUrl('login');
			return false;
		}

		return of(true);
	}
}
